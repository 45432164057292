import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./components/buttons/Login";
import Profile from "./components/Profile";
import LogoutButton from "./components/buttons/Logout";

export default function App() {
    const { isAuthenticated, isLoading } = useAuth0(); 

    if (isLoading) return <h1>Loading...</h1>;

    const loggedUI = (
	<div>
	    <Profile />
	    <LogoutButton />
	</div>
    );

    return (
	<div>
	    { isAuthenticated ? loggedUI : <LoginButton /> }
	</div>
    );
}
